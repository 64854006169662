import { Col, Row } from "react-bootstrap";
import "./Home.css";
import React from 'react'

export default function OpeningHours() {
  return (
    <div style={{ backgroundColor: '#F2F7F6', paddingTop: 30, paddingBottom: 30 }}>
      <Row>
        <Col className='ophours-container'>
          <Row>
            {/* <PunchTitle/> */}
          </Row>
          <Row>
            <h2 className='ophours-title'>Nos horaires d'ouverture</h2>
          </Row>
          <Row className='text-center m-2'>
            <p className="item-info-blue"><b>Lundi-vendredi : 8h-00h</b></p>
            <p className="item-info-blue"><b>Week-end et jours fériés : 8h- 20h</b></p>
          </Row>
        </Col>
        <Col>
          <Row className='align-items-end justify-content-center m-5'>
            <a className='button-primary' href="https://www.doctolib.fr/maison-de-sante/athis-mons/maison-de-sante-la-pyramide-athis-mons?utm_campaign=website-button&amp;utm_source=maison-de-sante-la-pyramide-athis-mons-website-button&amp;utm_mediu" target="_blank" rel="noopener noreferrer" data-reactroot="">
              <span>Rendez-vous vaccination</span>
            </a>
          </Row>
          <Row className='text-center m-2'>
            <p className="item-info-blue">En cas de consultation d’urgence, la maison médicale de garde (MMG) La Pyramide ouvre de 20h à minuit. Les rendez-vous sont pris uniquement par téléphone au secrétariat <b>01 69 48 46 78</b> ou SAMU centre 15 à partir de 17h.</p>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
