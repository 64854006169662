import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import HeaderDashboard from '../../header/HeaderDashboard';
import User from '../../login/User';
import FooterDashboard from '../../footer/FooterDashboard';
import { getMemberList, getSpecialties } from './TeamApi';
import AddMember from './AddMember';
import EditMember from './EditMember';
import DeleteMember from './DeleteMember';
import ReactPaginate from 'react-paginate';

export default function MemberList() {
    const [memberList, setMemberList] = useState([]);
    const [specialties, setSpecialties] = useState([])
    const [currentMember, setCurrentMember] = useState({});
    const [showAdditionModal, setShowAdditionModal] = useState(false);
    const [showEditionModal, setShowEditionModal] = useState(false);
    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        getMemberList().then((members) => setMemberList(members))
    }, []);
    
    useEffect(() => {
        getSpecialties().then((sps) => setSpecialties(sps))
    }, []);

    useEffect(() => {
        if (!showAdditionModal) getMemberList().then((members) => setMemberList(members))
        if (!showEditionModal) getMemberList().then((members) => setMemberList(members))
        if (!showDeletionModal) getMemberList().then((members) => setMemberList(members))
    }, [showAdditionModal, showEditionModal, showDeletionModal])

    const handleValuesToEdit = (memberId) => {
        setCurrentMember(memberList.find((member) => member.id === memberId))
        setShowEditionModal(true)
    }

    const handleValuesToAdd = () => {
        setShowAdditionModal(true)
    }

    const handleValuesToRemove = (memberId) => {
        setCurrentMember(memberList.find((member) => member.id === memberId))
        setShowDeletionModal(true)
    }

    const handlePageClick = (e) => {
        setOffset(e.selected * 10)
    };

    return (
        <div style={{ margin: '0' }}>
            <Container fluid>
                <Row>
                    <Col sm={12} md={12} lg={2}>
                        <HeaderDashboard />
                    </Col>
                    <Col sm={12} md={12} lg={10}>
                        <User />
                        <div className="container-datatable">
                            <div className="container-table">
                                <div className='d-flex justify-content-between align-items-center full-width'>
                                    <div>
                                        <img src="../../assets/images/add.png" alt="icon add" onClick={handleValuesToAdd} className='clickable' />
                                    </div>
                                    <div>
                                        {/* <ExcelFile 
                                        filename={'Abonés-'+new Date().toISOString().split('T')[0]}
                                        element={<div className='bt-green-def d-flex align-items-center'><i className="material-icons">file_download</i>&nbsp;Exporter les abonnés</div>}>
                                            <ExcelSheet data={memberList.map((e) => { return { ...e, name: e.last_name + ' ' + e.first_name } })} name="Abonés">
                                                <ExcelColumn label="Photo" value="photo" />
                                                <ExcelColumn label="Nom" value="name" />
                                                <ExcelColumn label="Email" value="email" />
                                                <ExcelColumn label="Téléphone" value="phone_number" />
                                                <ExcelColumn label="Ville" value="city" />
                                                <ExcelColumn label="Message" value="message" />
                                                <ExcelColumn label="Abonné(e) à la Newsletter" value={(col) => col.subscribed ? "Oui" : "Non"} />
                                            </ExcelSheet>
                                        </ExcelFile> */}
                                    </div>
                                </div>
                                <table className='full-width'>
                                    <thead>
                                        <tr className="container-column-sm">
                                            <th className="column-datatable col-10" key={1}>Photo</th>
                                            <th className="column-datatable col-10" key={2}>Nom</th>
                                            <th className="column-datatable col-10" key={3}>Email</th>
                                            <th className="column-datatable col-10" key={4}>Téléphone</th>
                                            <th className="column-datatable col-15" key={5}>Fonction</th>
                                            <th className="column-datatable col-15" key={8}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {memberList.slice(offset, offset + 10).map((member, index) => {
                                            return (
                                                <tr className="row-datatable" key={index}>
                                                    <td>
                                                        {member.image_name &&
                                                        <img className="mb-5" src={process.env.REACT_APP_S3_HOST + process.env.REACT_APP_IMAGES_TEAM_PATH + "/" + member.image_name} alt="specialist" height="160" />
                                                        }
                                                        {!member.image_name &&
                                                        <img className="mb-5" src='../assets/images/defImage.png' alt="specialist" height="160" />
                                                        }
                                                    </td>
                                                    <td>{member.first_name}&nbsp;{member.last_name}</td>
                                                    <td>{member.email}</td>
                                                    <td>{member.phone_number}</td>
                                                    <td>{specialties.find((sp) => sp.id === member.specialty)?.wording}</td>
                                                    <td>
                                                        <span className='clickable action-icon' onClick={() => handleValuesToEdit(member.id)}><i className="material-icons size-icono">edit</i></span>
                                                        <span className='clickable action-icon' onClick={() => handleValuesToRemove(member.id)}><i className="material-icons size-icono">delete</i></span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="container-pagination">
                                <ReactPaginate
                                    previousLabel={"Précédent"}
                                    nextLabel={"Suivant"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={Math.ceil(memberList.length / 10)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={handlePageClick.bind(this)}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FooterDashboard />
                    </Col>
                </Row>
            </Container>

            <AddMember show={showAdditionModal} specialties={specialties} closeFromChild={() => setShowAdditionModal(false)}></AddMember>
            <EditMember show={showEditionModal} member={currentMember} specialties={specialties} closeFromChild={() => setShowEditionModal(false)}></EditMember>
            <DeleteMember show={showDeletionModal} member={currentMember} closeFromChild={() => setShowDeletionModal(false)}></DeleteMember>
        </div>
    )
}
