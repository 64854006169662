import React from 'react'
import HeaderDashboard from '../../header/HeaderDashboard'
import User from '../../login/User'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FooterDashboard from '../../footer/FooterDashboard'
import API from '../../axios';
import { Button, Modal } from 'react-bootstrap'
import { Divider } from 'semantic-ui-react'
import './Videos.css'
import YouTube from 'react-youtube'

export default class VideoList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            videoList: [],
            currentVideoId: '',
            currentVideoTitle: '',
            currentVideoLink: '',
            isEditing: false,
            showModalAddEdit: false,
            showModalRemove: false,

            showTitleWarning: false,
            showLinkWarning1: false,
            showLinkWarning2: false,

            youtubeOpts: {
                height: '156',
                width: '256',
                playerVars: { autoplay: 0 }
            }
        }
    }

    handleCloseRemoveModal = () => {
        this.setState({ showModalRemove: false })
    }
    
    handleCloseAddEditModal = () => {
        this.setState({
            showModalAddEdit: false,
            showTitleWarning: false,
            showLinkWarning1: false,
            showLinkWarning2: false
        })
    }

    handleChangeTitle = (event) => {
        this.setState({currentVideoTitle: event.target.value})
        console.log(this.state.currentVideoTitle);
    }

    handleChangeLink = (event) => {
        this.setState({currentVideoLink: event.target.value})
        console.log(this.state.currentVideoLink);
    }

    handleValuesToAdd = () => {
        this.setState({
            currentVideoId: null,
            currentVideoTitle: '',
            currentVideoLink: '',
            showModalAddEdit: true,
            isEditing: false
        })
    }

    handleValuesToEdit = (video) => {
        this.setState({
            currentVideoId: video.id,
            currentVideoTitle: video.title,
            currentVideoLink: video.link,
            isEditing: true,
            showModalAddEdit: true
        })
    }

    handleValuesToRemove = (video) => {
        this.setState({
            currentVideoId: video.id,
            showModalRemove: true
        })
    }

    componentDidMount() {
        this.handleRequestVideos()
    }

    handleRequestVideos = () => {
        API.get('/video/get/all').then((response) => {
            const data = response.data.data;
            let dataPost = data.map((video) => {
                return {
                    id: video.id,
                    title: video.title,
                    link: video.link,
                    code: video.code
                }
            })

            this.setState({ videoList: dataPost })
        });
    }

    handleSubmitAddEdit = () => {
        let titleEmpty = false
        let linkEmpty = false
        let linkInvalid = false
        let regExp1 = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        
        if (!this.state.currentVideoTitle) titleEmpty = true
        if (!this.state.currentVideoLink) linkEmpty = true
        let match1 = this.state.currentVideoLink.match(regExp1);
        if (!match1 || match1[2].length !== 11) linkInvalid = true
        
        if(!titleEmpty && !linkEmpty && !linkInvalid) {
            let url = this.state.isEditing? '/video/edit' : '/video/create'

            API.post(
                url,
                { id: this.state.currentVideoId, title: this.state.currentVideoTitle, link: this.state.currentVideoLink }
            ).then(() => {
                this.handleRequestVideos()
                this.handleCloseAddEditModal()
            })
        } else {
            this.setState({
                showTitleWarning: titleEmpty,
                showLinkWarning1: linkEmpty,
                showLinkWarning2: linkEmpty? false: linkInvalid
            })
        }
    }

    handleRemoveVideo = () => {
        API.delete('/video/remove?id='+this.state.currentVideoId)
        .then(() => {
            this.handleRequestVideos()
            this.setState({showModalRemove: false})
        });
    }

    render() {
        return (
            <div style={{ margin: '0' }}>
                <Container fluid>
                    <Row>
                        <Col sm={12} md={12} lg={2}>
                            <HeaderDashboard />
                        </Col>
                        <Col sm={12} md={12} lg={10}>
                            <Row>
                                <User />
                            </Row>
                            <Row className='table-container'>
                                <Container>
                                    <div className='add-item-button' onClick={this.handleValuesToAdd}>
                                        <img src="../../assets/images/add.png" alt="icon add" />
                                    </div>
                                    <Divider></Divider>
                                    <table className='full-width'>
                                        <thead className='header-table'>
                                            <tr>
                                                <th className='col-25'>Titre</th>
                                                <th className='col-30'>Lien</th>
                                                <th className='col-30'>Aperçu</th>
                                                <th className='col-15'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.videoList.map((video, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{video.title}</td>
                                                        <td>{video.link}</td>
                                                        <td>
                                                            <YouTube videoId={video.code} opts={this.state.youtubeOpts} />
                                                        </td>
                                                        <td>
                                                            <span className='clickable action-icon' onClick={() => this.handleValuesToEdit(video)}><i className="material-icons size-icono">edit</i></span>
                                                            <span className='clickable action-icon' onClick={() => this.handleValuesToRemove(video)}><i className="material-icons size-icono">delete</i></span>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </Container>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FooterDashboard />
                        </Col>
                    </Row>
                </Container>

                <Modal show={this.state.showModalAddEdit} onHide={this.handleCloseAddEditModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.isEditing ? 'Publier la vidéo' : 'Ajouter une vidéo'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <label className='col-md-12'>
                                    Titre *:
                                    <input className='form-control mb-2' type="text" value={this.state.currentVideoTitle} onChange={this.handleChangeTitle} />
                                    {this.state.showTitleWarning ? <span className='validation-warning'>Champ requis</span> : null}
                                </label>
                            </Row>
                            <Row>
                                <label className='col-md-12'>
                                    Lien:
                                    <input className='form-control mb-2' type="text" value={this.state.currentVideoLink} onChange={this.handleChangeLink} />
                                    {this.state.showLinkWarning1 ? <span className='validation-warning'>Champ requis</span> : null}
                                    {this.state.showLinkWarning2 ? <span className='validation-warning'>URL YouTube non valide</span> : null}
                                </label>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleCloseAddEditModal}>
                                Annuler
                            </Button>
                            <Button variant="primary" onClick={this.handleSubmitAddEdit}>
                                Confirmer
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={this.state.showModalRemove} onHide={this.handleCloseRemoveModal}>
                <form>
                    <Modal.Header closeButton>
                        <Modal.Title>Voulez-vous vraiment supprimer la vidéo ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseRemoveModal}>
                            Annuler
                        </Button>
                        <Button variant="primary" onClick={this.handleRemoveVideo}>
                            Confirmer
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            </div>
        )
    }
}
