import API from '../../axios';

export const getMemberList = async () => {
    let response = await API.get('/team/get/all')
    
    return response.data.data?.map((member) => {
        return {
            ...member,
            persisted: true
        }
    })
}

export const getSpecialties = async () => {
    let response = await API.get('/team/get/specialties')

    return response.data.data
}

export const addMember = async (member) => {
    const formData = new FormData();
    
    formData.append('firstName', member.first_name)
    formData.append('lastName', member.last_name)
    formData.append('description', member.description)
    formData.append('specialty', member.specialty)
    formData.append('gender', member.gender)
    formData.append('link', member.link)
    formData.append('button', member.button)
    formData.append('link2', member.link2)
    formData.append('button2', member.button2)
    formData.append('image', member.image)
    formData.append('imgFormat', member.image? member.image.name.split('.').pop(): '')
    
    return await API({
        method: 'post',
        url: 'team/add/member',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}

export const editMember = async (member) => {
    const formData = new FormData();
    
    formData.append('id', member.id)
    formData.append('firstName', member.first_name)
    formData.append('lastName', member.last_name)
    formData.append('description', member.description)
    formData.append('specialty', member.specialty)
    formData.append('gender', member.gender)
    formData.append('link', member.link)
    formData.append('button', member.button)
    formData.append('link2', member.link2)
    formData.append('button2', member.button2)
    formData.append('imageName', member.image_name)
    formData.append('image', member.image)
    formData.append('imgFormat', member.image? member.image.name.split('.').pop(): '')

    return await API({
        method: 'put',
        url: 'team/edit/member?id=' + member.id,
        data: formData,
        headers: { 'Content-Type': 'application/json' }
    })
}

export const deleteMember = async (member) => {
    return await API({
        method: 'delete',
        url: 'team/remove/member',
        data: { memberId: member.id, imageName: member.image_name },
        headers: { 'Content-Type': 'application/json' }
    })
}

export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };