import React, { Component } from 'react'
import Header from '../header/Header'
import Landing from './Landing'
import About from './About'
import Carousel from './Carousel';
import GeneralInfo from './GeneralInfo';
import Footer from '../footer/Footer'
import OpeningHours from './OpeningHours';

export default class Home extends Component {

    render() {
        return (
            <div>
                <Header/>
                <Landing/>
                <OpeningHours/>
                <Carousel/>
                {/* <CounterGroup/> */}
                <About/>
                <GeneralInfo/>
                <Footer />
            </div>
        )
    }
}

