import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import API from '../axios'
import { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import MemberGrid from './MemberGrid'

export default function Team() {
  const [team, setTeam] = useState(null)
  const [specialties, setSpecialties] = useState([])

  useEffect(() => {
    API.get('team/get/specialties').then((response) => {
      setSpecialties(response.data.data)
    });

    API.get('/team/get/all').then((response) => {
      setTeam(response.data.data)
    })
  }, [])

  return (
    <div>
      <Header />
      <Container>
        <br />
        <Row>
          <Tabs defaultActiveKey="evenements" id="posts" className="mt-2 mb-2" justify>
            <Tab eventKey="evenements" title="Spécialités médicales">
              <Container className="container-updates">
                {team &&
                  <MemberGrid
                    members={team.filter((member) => [1, 2].includes(member.specialty))}
                    specialties={specialties}
                  />
                }
              </Container>
            </Tab>
            <Tab eventKey="liensutiles" title="Médecine générale">
              <Container className="container-updates">
                {team &&
                  <MemberGrid
                    members={team.filter((member) => [3].includes(member.specialty))}
                    specialties={specialties}
                  />
                }
              </Container>
            </Tab>
            <Tab eventKey="infosante" title="Autres professions libérales">
              <Container className="container-updates">
                {team &&
                  <MemberGrid
                    members={team.filter((member) => [4, 5].includes(member.specialty))}
                    specialties={specialties}
                  />
                }
              </Container>
            </Tab>
          </Tabs>

        </Row>
        <Row>

          <Col className="container-info-team color-blue">
            <p><b>Horaires d'ouverture: </b><br />Ouverte du Lundi au Vendredi de 8h à minuit<br /> le week-end et jours fériés de 8h à 20h</p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}