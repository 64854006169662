import React from 'react'
import './subheader.css';

export default function Subheader() {
    return (
        <div className="subheader">
            <div className="slider">
                <div className="slide-track">
                    <div className="slide">
                        <span>N'ATTENDEZ PLUS POUR VOUS FAIRE VACCINER À LA MSP LA PYRAMIDE.</span>
                    </div>
                    <div className="slide">
                        <span>LA MAISON MÉDICALE DE GARDE (MMG) EST OUVERTE DU LUNDI AU VENDREDI 20H-00H. POUR OBTENIR UN RDV DE CONSULTATION D'URGENCE, MERCI D'APPELER LE SECRÉTARIAT AU 01 69 48 46 78 OU LE SAMU CENTRE 15 À PARTIR DE 17H.</span>
                    </div>
                    <div className="slide">
                        <span>BESOIN D'UNE INFIRMIÈRE ? NOTRE ÉQUIPE ASSURE DES SOINS INFIRMIERS À LA MSP ET À DOMICILE POUR PLUS D'INFORMATIONS, VEUILLEZ CONTACTER LE SECRÉTARIAT.</span>
                    </div>
                    <div className="slide">
                        <span>N'ATTENDEZ PLUS POUR VOUS FAIRE VACCINER À LA MSP LA PYRAMIDE.</span>
                    </div>
                    <div className="slide">
                        <span>LA MAISON MÉDICALE DE GARDE (MMG) EST OUVERTE DU LUNDI AU VENDREDI 20H-00H. POUR OBTENIR UN RDV DE CONSULTATION D'URGENCE, MERCI D'APPELER LE SECRÉTARIAT AU 01 69 48 46 78 OU LE SAMU CENTRE 15 À PARTIR DE 17H.</span>
                    </div>
                </div>
            </div>
        </div>
    )

}