import "./Home.css";
import React, { Component } from 'react'
import PunchTitle from './PunchTitle';

export default class Landing extends Component {
  render() {
    return (
      <div className="row landing-container">
        <div className="col-md-6">
          <div className='jumbotron' style={{ backgroundImage: `url(../assets/images/principal.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div className="container-jumbotron">
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          <PunchTitle />
        </div>
      </div>
    )
  }
}
