import * as Yup from 'yup';

export const validateSchema = Yup.object().shape({
    first_name: Yup.string()
        .min(2, 'Trop court!')
        .max(16, 'Trop long!')
        .required('Requis'),
    last_name: Yup.string()
        .min(2, 'Trop court!')
        .max(16, 'Trop long!')
        .required('Requis'),
    phone_number: Yup.number()
        .typeError("Cela ne ressemble pas à un numéro de téléphone")
        .positive('Numéro invalide'),
    email: Yup.string()
        .email('Email invalide'),
    specialty: Yup.number()
        .positive('Champs requis')
        .required('Requis'),
    gender: Yup.string()
        .required('Requis'),
    link: Yup.string()
        .max(200, 'Trop long!'),
    button: Yup.string()
        .max(50, 'Trop long!'),
    link2: Yup.string()
        .max(200, 'Trop long!'),
    button2: Yup.string()
        .max(50, 'Trop long!')
});