import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import Subheader from '../subheader/Subheader'

export default class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tooltip: true
        }
    }

    render() {
        let class_tooltip = this.state.tooltip ? "hiddenTooltip" : "visibleTooltip";
        return (
            <header id="header" className="header mt-3">
                <div className="branding">
                    <div className="container-fluid fixed-container">
                        <nav className="main-nav navbar navbar-expand-xl" >
                            <div className="container-sm-header">
                                <Link className="scrollto" to="/">
                                    <img className="header-logo" src="../assets/images/logo-blue.png" alt="logo" />
                                </Link>
                                <Link className="scrollto" to="/">
                                    <img className="header-logo" src="../assets/images/logo-red.png" alt="logo" />
                                </Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                                    <img src="../assets/images/menu.png" alt="icono menu" />
                                </button>
                            </div>
                            <div id="navigation" className="collapse navbar-collapse justify-content-md-center justify-content-lg-end mr-lg-3">
                                <ul className="nav navbar-nav container-header-main fixed-container-sm">
                                    <li className="nav-item"><NavLink className="nav-link scrollto main-menu-a" to="/" exact activeClassName="current">Nos horaires</NavLink></li>
                                    <li className="nav-item"><NavLink className="nav-link scrollto main-menu-a" to="/team" activeClassName="current">L'équipe </NavLink></li>
                                    <li className="nav-item"><NavLink className="nav-link scrollto main-menu-a" to="/posts" activeClassName="current">Actualités</NavLink></li>
                                    <li className="nav-item"><NavLink className="nav-link scrollto main-menu-a" to="/gallery" activeClassName="current">Galerie de photos</NavLink></li>
                                    <li className="nav-item"><NavLink className="nav-link scrollto main-menu-a" to="/contact" activeClassName="current">Prendre rendez-vous</NavLink></li>
                                    <li className="nav-item">
                                        <Link className="nav-link scrollto link-login" to="/login" onMouseEnter={() => this.setState({ tooltip: false })} onMouseLeave={() => this.setState({ tooltip: true })}><span className="material-icons material-icons-login">person</span></Link>
                                        <p className={class_tooltip} >
                                            Accès Admin
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <Subheader />
                </div>
            </header>
        )
    }
}
